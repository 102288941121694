.hover-purple:hover {
    color: #7c3aed;
}

.text-purple {
    color: #7c3aed;
}

.bg-purple {
    background-color: #7c3aed;
}

html {
    scroll-behavior: smooth;
}
